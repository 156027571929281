/****************************************************

	Utilities

****************************************************/
import { animateScroll as scroll } from "react-scroll"

/******************************************************
	Helper files
******************************************************/
export {
  shuffleArray,
  intersectArrays,
  diffArrays,
  symDiffArrays,
  unionArrays,
  removeDuplicates,
  getFirstArrayItem,
  filterByType,
  isEmptyArray,
} from "./utils_arrays"
export { filterSelectStyles } from "./utils_constants"
export { getAltText, buildShareImageMeta } from "./utils_images"
export {
  getLink,
  getPrettyURL,
  buildBreadcrumbs,
  getUrlStructure,
  getPageSlugWithPrefix,
} from "./utils_links"
export {
  formatFormDate,
  getHeroHeading,
  getPlainText,
  isEmptyRichText,
} from "./utils_text"
export { convertBase64, buildAssetUpload } from "./utils_files"

/******************************************************
	Animate page scroll
******************************************************/
export const scrollToRef = (
  ref: React.RefObject<HTMLElement>,
  offset: number = 0,
  callback: () => void = () => {}
) => {
  if (ref?.current) {
    const top = ref.current.getBoundingClientRect().top - offset
    scroll.scrollMore(top, {
      duration: 0,
      smooth: "linear",
      delay: 0,
    })

    setTimeout(() => callback(), 300)
  }
}

export const showLanguageSelector = () => {
    return process.env.GASTBY_SHOW_LANGUAGE_SELECTOR === "true";
}
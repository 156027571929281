/****************************************************

	Utilities - Files

****************************************************/
import { AssetUpload } from "../models/common"
import { SF_ASSET_LOOKUP } from "./utils_constants"
import { v4 as uuidv4 } from "uuid"

/****************************************************
	convert file to base64 encoding
****************************************************/
export const convertBase64 = (
  file: Blob
): Promise<string | ArrayBuffer | null> | undefined => {
  if (!file) return undefined
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

/****************************************************
	build Salesforce asset upload data
****************************************************/
export const buildAssetUpload = (
  file: any,
  sfImageCategory: any
): AssetUpload | undefined => {
  if (!file) return undefined
  const type: string = file.mimetype.split("/")[1]
  return {
    key: file.fieldname,
    name: file.originalname.split(".")[0] + uuidv4(),
    category: { id: sfImageCategory },
    FileProperties: {
      fileName: file.originalname,
    },
    assetType: {
      name: type,
      id: SF_ASSET_LOOKUP[type],
    },
    file: file.buffer.toString("base64"),
  }
}
